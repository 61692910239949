<template>
  <!--begin::Quotation Listing-->
  <div class="quotation-template">
    <PageHeaderCount
      moduleType="quotation"
      :dataLoading="dataLoading"
      :statusList="statusList"
      allkey="all_quotations"
      :countkey="
        isQuotation
          ? 'quotation_status_count'
          : 'quotation_package_status_count'
      "
    ></PageHeaderCount>
    <ListingTemplate
      :customClass="'quotation-listing'"
      v-if="getPermission('quotation:view')"
    >
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-col cols="4" class="pt-0">
                <h1
                  class="form-title custom-nowrap-ellipsis margin-auto selected-rows-text"
                >
                  {{ totalSelected }} Selected
                </h1>
              </v-col>
              <v-col cols="8" class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('quotation:update')">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    left
                    offset-y
                    transition="slide-y-transition"
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading"
                        class="ml-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(more, index) in bulkActions">
                        <v-list-item
                          link
                          v-on:click="bulkActionDialog(more.action, more.title)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              more.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ more.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="6" class="pt-0">
                <h1 class="form-title d-flex margin-auto">
                  <v-select
                    :disabled="dataLoading"
                    :items="statusList"
                    v-model="status"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="item.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template v-if="item.value == 'all'">{{
                            item.all_quotations
                          }}</template>
                          <template v-else>
                            <span v-if="isQuotation">{{
                              item.quotation_status_count
                            }}</span>
                            <span v-else>{{
                              item.quotation_package_status_count
                            }}</span>
                          </template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                </h1>
              </v-col>
              <v-col cols="6" class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('quotation:create')">
                  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button"
                    v-on:click="select_package_quotation()"
                    color="cyan white--text"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn>
                <v-btn
                  v-if="false"
                  v-on:click="
                    $router.push(
                      getDefaultRoute('profile.setting', {
                        query: {
                          tab: 'quotation',
                        },
                      })
                    )
                  "
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                >
                  <v-icon dark>mdi-cog</v-icon>
                </v-btn>
                <v-tooltip v-if="false" top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on:click="exportQuotations()"
                      :disabled="dataLoading"
                      v-bind="attrs"
                      v-on="on"
                      class="mx-2 custom-grey-border custom-bold-button"
                      color="cyan white--text"
                    >
                      <v-icon dark>mdi-file-excel-box-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Download Excel</span>
                </v-tooltip>
                <template v-if="false">
                  <v-menu content-class="custom-menu-list" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading || exportLoading"
                        class="mx-2 custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                        :loading="exportLoading"
                      >
                        <v-icon dark>mdi-menu</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(item, index) in moreActions">
                        <v-list-item
                          :key="index"
                          link
                          :class="{ 'line-active': item.sort }"
                          v-on:click="moreAction(item.action, item.sort)"
                        >
                          <v-list-item-title>
                            <v-icon v-if="item.icon" small left color="cyan">{{
                              item.icon
                            }}</v-icon>
                            {{ item.title }}
                            <template v-if="item.sort">
                              <v-icon
                                v-if="item.sort == 'asc'"
                                small
                                right
                                color="cyan"
                                >mdi-sort-ascending</v-icon
                              >
                              <v-icon
                                v-if="item.sort == 'desc'"
                                small
                                right
                                color="cyan"
                                >mdi-sort-descending</v-icon
                              >
                            </template>
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  left
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="ml-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="cols.fixed || dataLoading"
                          color="cyan"
                          hide-details
                          class="pt-2 mt-0"
                        ></v-checkbox>
                        <v-icon
                          class="draggable-drag-icon"
                          small
                          right
                          color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
                <!-- <PageTips module="quotation"></PageTips> -->
              </v-col>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          :pageModule="get_page_title()"
          :basicSearchFields="[
            `${get_page_title()} #`,
            'reference',
            'package_title',
            'sales',
            'prepared_by',
          ]"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="position: static"
        >
          <ListingTable
            :columnCount="defaultColShow.length"
            :dataLoading="dataLoading"
            :rowData="rowData"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in defaultColDefs">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      class="simple-table-th"
                      :class="{
                        'checkbox-width-limit dbx-checkbox': cols.checkbox,
                        'dbx-barcode': cols.field == 'barcode',
                        'dbx-added-at': cols.field == 'added_at',
                        'dbx-updated-at': cols.field == 'updated_at',
                      }"
                      :style="{ 'max-width': cols.width }"
                    >
                      <template v-if="cols.checkbox">
                        <v-checkbox
                          dense
                          :disabled="dataLoading"
                          v-model="selectedAll"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>
                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <template v-if="rowData.length > 0">
                  <tr v-for="(data, index) in rowData" :key="index" link>
                    <template v-for="cols in defaultColDefs">
                      <td
                        v-if="cols.visible"
                        :key="cols.id"
                        :class="{
                          'dbx-checkbox': cols.checkbox,
                          'dbx-barcode': cols.field == 'barcode',
                          'dbx-added-at': cols.field == 'added_at',
                          'dbx-updated-at': cols.field == 'updated_at',
                          'simple-table-td': !cols.checkbox,
                          'checkbox-width-limit': cols.checkbox,
                        }"
                        :style="{ 'max-width': cols.width }"
                        v-on:click="
                          !cols.checkbox
                            ? rowClicked(data)
                            : 'javascript:void(0)'
                        "
                      >
                        <template v-if="cols.checkbox">
                          <v-checkbox
                            dense
                            v-model="selectedRows"
                            v-bind:value="data.id"
                            color="cyan"
                            class="hide-margin"
                            hide-details
                          ></v-checkbox>
                        </template>
                        <template v-else-if="cols.field == 'barcode'">
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <Barcode
                                  route="quotation.detail"
                                  :barcode="data.barcode"
                                  :id="data.id"
                                ></Barcode>
                              </div>
                            </template>
                            <span>{{ get_page_title() }} #</span>
                          </v-tooltip>

                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                v-bind="attrs"
                                v-on="on"
                                v-if="data.variation"
                                v-on:click.stop.prevent
                              >
                                <Barcode
                                  is-variation
                                  route="pre.quotation.detail"
                                  :barcode="data.variation.barcode"
                                  :id="data.variation.id"
                                ></Barcode>
                              </div>
                            </template>
                            <span>Pre Quotation</span>
                          </v-tooltip>

                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                v-bind="attrs"
                                v-on="on"
                                v-if="data.package"
                                v-on:click.stop.prevent
                              >
                                <Barcode
                                  is-package
                                  route="quotation.detail"
                                  :barcode="data.package.barcode"
                                  :id="data.package.id"
                                ></Barcode>
                              </div>
                            </template>
                            <span>Package Sales</span>
                          </v-tooltip>
                        </template>
                        <template v-else-if="cols.field == 'date'">
                          <p
                            class="m-0 custom-nowrap-ellipsis"
                            v-if="data.date"
                          >
                            <b>Date: </b> {{ formatDate(data.date) }}
                          </p>
                          <p
                            class="m-0 custom-nowrap-ellipsis"
                            v-if="data.open_till"
                          >
                            <b>Open Till: </b> {{ formatDate(data.open_till) }}
                          </p>
                          <p
                            class="m-0 custom-nowrap-ellipsis"
                            v-if="data.sent_date"
                          >
                            <b>Sent: </b> {{ formatDate(data.sent_date) }}
                          </p>
                        </template>
                        <template v-else-if="cols.field == 'quotation_title'">
                          <div class="quotation-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>{{ get_page_title() }} Title: </b
                              >{{ data.quotation_title }}
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis-two-line">
                              <b>Site Address: </b>
                              <ShowValue
                                :object="data"
                                object-key="billing.property_address"
                                label="renovation address"
                              ></ShowValue>
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'basic_detail'">
                          <div class="lead-listing">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Title: </b>
                              <ShowValue
                                :object="data"
                                object-key="quotation_title"
                                label="title"
                              ></ShowValue>
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Customer: </b>
                              <ShowValue
                                :object="data"
                                object-key="customer.display_name"
                                label="reference"
                              ></ShowValue>
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Prepared by: </b>
                              <ShowValue
                                :object="data"
                                object-key="prepared_by"
                                label="prepared by"
                              ></ShowValue>
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>CO-Designer: </b>
                              <ShowValue
                                :object="data"
                                object-key="assistant_relation.full_name"
                                label="co-designer"
                              ></ShowValue>
                            </p>
                          </div>
                          <div v-if="false" class="quotation-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Reference: </b>
                              <template v-if="data.reference">{{
                                data.reference
                              }}</template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Reference</em
                                ></template
                              >
                            </p>
                            <p v-if="false" class="m-0 custom-nowrap-ellipsis">
                              <b>Sales representative: </b>
                              <template v-if="data.sales">{{
                                data.sales
                              }}</template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Sales representative</em
                                ></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Prepared by: </b>
                              <template v-if="data.prepared_by">{{
                                data.prepared_by
                              }}</template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Prepared by</em
                                ></template
                              >
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'status'">
                          <div class="quotation-listing-status text-center">
                            <v-chip
                              v-if="data.quote_status_obj"
                              label
                              small
                              class="custom-status font-weight-600 custom-grey-border text-uppercase mx-2"
                              :color="data.quote_status_obj.color"
                              :text-color="data.quote_status_obj.textcolor"
                            >
                              {{ data.quote_status_obj.text }} 
                            </v-chip>
                            <template v-if="isQuotation">
                              <v-chip
                                v-if="data.variation_level > 0"
                                small
                                label
                                color="green white--text"
                                class="d-inline font-size-12 text-uppercase font-weight-600"
                              >
                                Pre Quotation
                              </v-chip>
                              <v-chip
                                v-if="data.parent_variation > 0"
                                small
                                label
                                color="orange white--text"
                                class="d-inline font-size-12 text-uppercase font-weight-600"
                              >
                                Variation
                              </v-chip>
                            </template>
                            <template v-if="isPackage && data.has_case_trust">
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip
                                    v-bind="attrs"
                                    v-on="on"
                                    color="cyan lighten-4"
                                    class="mx-2 d-inline black--text font-weight-600"
                                    label
                                    small
                                  >
                                    CASE TRUST
                                  </v-chip>
                                </template>
                                <span>Case trust generated</span>
                              </v-tooltip>
                            </template>
                            <template v-if="false">
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip
                                    v-bind="attrs"
                                    v-on="on"
                                    color="red lighten-4"
                                    class="mx-2 d-inline black--text font-weight-600"
                                    label
                                    small
                                  >
                                    REVISED
                                  </v-chip>
                                </template>
                                <span>Quotation is Revised</span>
                              </v-tooltip>
                            </template>
                            <template v-if="false">
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip
                                    v-bind="attrs"
                                    v-on="on"
                                    color="teal lighten-4"
                                    class="mx-2 d-inline black--text font-weight-600"
                                    label
                                    small
                                  >
                                    REVISION
                                  </v-chip>
                                </template>
                                <span
                                  >Revision of {{ data.revise.barcode }}</span
                                >
                              </v-tooltip>
                            </template>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'company_info'">
                          <div class="quotation-listing-customer">
                            <p
                              class="m-0 custom-nowrap-ellipsis"
                              v-if="data.customer"
                            >
                              <b>Display Name: </b>
                              <ShowValue
                                :object="data"
                                object-key="customer.display_name"
                                label="display name"
                              ></ShowValue>
                            </p>
                            <p
                              class="m-0 custom-nowrap-ellipsis"
                              v-if="data.customer"
                            >
                              <b>Company: </b>
                              <ShowValue
                                :object="data"
                                object-key="customer.company_name"
                                label="company name"
                              ></ShowValue>
                            </p>
                            <p
                              class="m-0 custom-nowrap-ellipsis"
                              v-if="data.contact_person"
                            >
                              <b>Email: </b>
                              <ShowValue
                                :object="data"
                                object-key="contact_person.primary_email"
                                label="email"
                              ></ShowValue>
                            </p>
                            <p
                              class="m-0 custom-nowrap-ellipsis"
                              v-if="data.contact_person"
                            >
                              <b>Phone: </b>
                              <ShowValue
                                :object="data"
                                object-key="contact_person.primary_phone"
                                label="phone"
                              ></ShowValue>
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'amount'">
                          <div class="quotation-listing-amount">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Optional Total: </b>
                              {{
                                $accountingJS.formatMoney(data.optional_total)
                              }}
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Sub Total: </b>
                              {{ $accountingJS.formatMoney(data.sub_total) }}
                            </p>
                            <p
                              v-if="data.tax_amount > 0"
                              class="m-0 custom-nowrap-ellipsis"
                            >
                              <b>GST [{{data.tax_value}}%]: </b>
                              {{ $accountingJS.formatMoney(data.tax_amount) }}
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Total: </b>
                              {{ $accountingJS.formatMoney(data.total) }}
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'added_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.added_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.added_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.created_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.added_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else-if="cols.field == 'updated_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.updated_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.updated_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.modified_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.updated_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else>
                          <div v-html="printRowData(cols, data)"></div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-else-if="!dataLoading">
                  <td :colspan="defaultColDefs.length" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no {{ get_page_title() }} at the moment.
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>
        <ListingFooter
          :dataLoading="dataLoading"
          :showingFrom="showingFrom"
          :showingTo="showingTo"
          :totalRows="totalRows"
          :currentPage="currentPage"
          :totalPages="totalPages"
        ></ListingFooter>
        <Dialog :common-dialog="searchDialog">
          <template v-slot:title>Filter {{ get_page_title() }}</template>
          <template v-slot:body>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="position: static"
            >
              <v-container fluid class="pt-0 custom-search-filter">
                <v-row>
                  <v-col md="6">
                    <v-menu
                      v-model="datePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          filled
                          flat
                          label="Dates Range"
                          hide-details
                          solo
                          clearable
                          v-on:click:clear="dates = []"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :value="formattedDate"
                          color="cyan"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="cyan"
                        range
                        v-model="dates"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <template v-for="(field, index) in searchableArray">                  
                    <template v-if="field == 'prepared_by'">
                      <v-col md="6" :key="index">
                        <v-autocomplete
                          dense
                          color="cyan"
                          filled
                          :items="userList"
                          v-model="listingSearch[field]"
                          :label="getFilterLabel(field, 'Quotation')"
                          solo
                          flat
                          item-color="cyan"
                          item-text="full_name"
                          item-value="full_name"
                          multiple
                          hide-details
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="'No User Found.'"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </template>
                    <v-col md="6" :key="index" v-else-if="field != 'dates'">
                      <v-text-field
                        v-model.trim="listingSearch[field]"
                        dense
                        filled
                        hide-details
                        :label="getFilterLabel(field, get_page_title())"
                        solo
                        flat
                        clearable
                        @keydown.enter="filterRows"
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </perfect-scrollbar>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="filterRows"
            >
              Filter
            </v-btn>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>
        <Dialog :common-dialog="selectPackageDialog">
          <template v-slot:title>
            <v-layout>
              <v-flex> Select Quotation </v-flex>
              <v-flex class="text-right">
                <v-btn icon v-on:click="selectPackageDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </template>
          <template v-slot:body>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll"
              style="position: static"
            >
              <v-container fluid class="pt-0">
                <v-text-field
                  ref="search-customer"
                  v-model.trim="search_text"
                  :loading="search_loader"
                  autocomplete="off"
                  class="mx-2 mx-md-4 rounded-lg"
                  dense
                  placeholder="Start Typing then press enter"
                  hide-details
                  solo
                  @keydown.esc="get_quotation_packages()"
                  @keydown.enter="get_quotation_packages()"
                  @keydown.tab="get_quotation_packages()"
                >
                  <template #prepend-inner>
                    <v-icon color="grey" class="ml-1 mr-2">
                      mdi-magnify
                    </v-icon>
                  </template>
                </v-text-field>
                <br />
                <v-list class="mx-2 mx-md-4" subheader three-line>
                  <v-skeleton-loader
                    v-if="search_loader"
                    class="custom-skeleton"
                    type="list-item-two-line"
                  >
                  </v-skeleton-loader>
                  <template v-else>
                    <template v-if="quotation_packages.length > 0">
                      <v-list-item
                        v-for="(data, index) in quotation_packages"
                        v-on:click="select_package(data)"
                        :key="index"
                        link
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            class="font-weight-500 font-size-16 pt-2"
                          >
                            <Barcode :barcode="data.barcode" small></Barcode>
                            <p class="my-1">{{ data.quotation_title }}</p>
                          </v-list-item-title>
                          <v-list-item-subtitle
                            v-if="data.raw_property"
                            class="font-weight-500 font-size-14"
                          >
                            {{ data.raw_property.property_address }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template v-else>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class="font-weight-500 font-size-16"
                            >No Quotation(s) Found
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </template>
                </v-list>
              </v-container>
            </perfect-scrollbar>
          </template>
        </Dialog>
        <template v-if="customerDialog">
          <CustomerDialog
            is-quotation
            :customer-dialog="customerDialog"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectCustomer="selectCustomer"
            v-on:selectCustomerPerson="selectCustomerPerson"
            v-on:selectCustomerProperty="selectCustomerProperty"
            v-on:selectCustomerBilling="selectCustomerBilling"
          ></CustomerDialog>
        </template>
        <template v-if="customerPersonDialog">
          <CustomerPersonDialog
            is-quotation
            :customer-person-dialog="customerPersonDialog"
            :customer="customer"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectCustomerPerson="selectCustomerPerson"
          ></CustomerPersonDialog>
        </template>
        <template v-if="customerPropertyDialog">
          <CustomerPropertyDialog
            is-quotation
            :customer-property-dialog="customerPropertyDialog"
            :customer="customer"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectCustomerProperty="selectCustomerProperty"
          ></CustomerPropertyDialog>
        </template>
      </template>
    </ListingTemplate>
    <Dialog :commonDialog="btxBulkDialog">
      <template v-slot:title>Confirmation</template>
      <template v-slot:body>
        <v-container fluid class="pt-0 custom-search-filter">
          <table width="100%">
            <tr>
              <td width="10%"></td>
              <td>
                Are you sure, you want to mark selected items as
                <span class="font-weight-600">{{ btxBulkText }}</span
                >?
              </td>
            </tr>
          </table>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="btxBulkLoading"
          :loading="btxBulkLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          color="cyan white--text"
          v-on:click="doBulkAction()"
        >
          Yes! Confirm
        </v-btn>
        <v-btn
          :disabled="btxBulkLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="
            btxBulkDialog = false;
            btxBulkAction = null;
          "
        >
          No! Cancel
        </v-btn>
      </template>
    </Dialog>
  </div>
  <!--end::Quotation Listing-->
</template>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import QuotationMixin from "@/core/lib/quotation/quotation.mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { QUERY, PATCH, DOWNLOAD } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import CustomerDialog from "@/view/pages/partials/Select-Customer.vue";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
import CustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
import { saveAs } from "file-saver";
// import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import ShowValue from "@/view/pages/partials/Show-Value.vue";
import { SearchEventBus } from "@/core/lib/search.lib";
import ObjectPath from "object-path";

export default {
  mixins: [CommonMixin, ListingMixin, QuotationMixin],
  name: "quotation-list",
  data() {
    return {
      selectPackageDialog: false,
      quotation_packages: [],
      exportLoading: false,
      search_loader: false,
      search_text: null,
      dates: [],
      userList: [],
      pageModule: "quotation-listing",
      routeAPI: "quotation",
      routeName: "quotation",
      routeDetailName: "quotation.detail",
      status: "all",
      statusList: [],
      moreActions: [
        {
          title: "Export Quotation(s)",
          action: "export_items",
          divider: false,
          icon: "mdi-database-export",
        },
        {
          title: "Export Current View",
          action: "export_current_view",
          divider: true,
          icon: "mdi-database-export",
        },
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      bulkActions: [
        {
          title: "Mark as Accepted",
          icon: "mdi-check-all",
          action: "accepted",
        },
        {
          title: "Mark as Rejected",
          icon: "mdi-check-all",
          action: "rejected",
        },
        /*{
          title: "Mark as Pending",
          icon: "mdi-email-receive",
          action: "pending",
        },
        {
          title: "Send as Email",
          icon: "mdi-email-send",
          action: "sent"
        },
        {
          title: "Mark as Cancelled",
          icon: "mdi-check-all",
          action: "cancelled",
        },
        {
          title: "Mark as Closed",
          icon: "mdi-check-all",
          action: "closed",
        },*/
      ],
      btxBulkLoading: false,
      btxBulkDialog: false,
      btxBulkAction: null,
      btxBulkText: null,
    };
  },
  props: {
    isQuotation: {
      type: Boolean,
      default: false,
    },
    isPackage: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    //PageTips,
    ShowValue,
    draggable,
    PageHeaderCount,
    Barcode,
    TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog,
    CustomerDialog,
    CustomerPropertyDialog,
    CustomerPersonDialog,
  },
  watch: {
    dates: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param.length > 1) {
          this.listingSearch["dates"] = param;
        } else {
          this.listingSearch["dates"] = [];
        }
      },
    },
  },
  methods: {
    bulkActionDialog(action, text) {
      this.btxBulkDialog = true;
      this.btxBulkAction = action;
      this.btxBulkText = text;
    },
    doBulkAction() {
      this.updateBulkAction(this.btxBulkAction);
      this.btxBulkDialog = false;
      this.btxBulkAction = null;
      this.btxBulkText = null;
    },
    select_package_quotation() {
      if (this.isPackage) {
        this.customerDialog = true;
      } else {
        this.selectPackageDialog = true;
      }
    },
    getDefaultPerson(defaultPerson) {
      if (this.lodash.isEmpty(defaultPerson)) {
        return "";
      }
      return defaultPerson.display_name;
    },
    exportQuotations() {
      this.exportLoading = true;
      let fileName = new Date();
      this.$store
        .dispatch(DOWNLOAD, {
          url: `quotation/export?is-package=${this.isPackage}`,
          data: this.queryParams,
        })
        .then(({ data }) => {
          const file_name = this.isPackage ? "packages" : "quotations";
          saveAs(data, `${file_name}-report-export-${fileName.getTime()}.xlsx`);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.exportLoading = false;
        });
    },
    resetAll() {
      this.customer = 0;
      this.customerPerson = 0;
      this.customerBilling = 0;
      this.closeDialog();
    },
    closeDialog() {
      this.customerDialog = false;
      this.customerPersonDialog = false;
      this.customerPropertyDialog = false;
      this.customerBillingDialog = false;
    },
    selectCustomer(param) {
      this.customer = this.lodash.toSafeInteger(param);
      this.closeDialog();
      this.checkQuotation();
    },
    selectCustomerPerson(param) {
      this.customerPerson = this.lodash.toSafeInteger(param);
      this.closeDialog();
      this.checkQuotation();
      // this.customerPropertyDialog = true;
    },
    selectCustomerProperty(param) {
      this.customerProperty = this.lodash.toSafeInteger(param);
      this.closeDialog();
      this.checkQuotation();
    },
    selectCustomerBilling(param) {
      this.customerBilling = this.lodash.toSafeInteger(param);
    },
    checkQuotation() {
      const _this = this;
      if (
        _this.customer > 0 &&
        _this.customerProperty > 0 &&
        _this.customerBilling > 0
      ) {
        let route_name = "quotation.create";
        if (this.isPackage) {
          route_name = "package.sales.create";
        }

        _this.$router.push(
          _this.getDefaultRoute(route_name, {
            query: {
              customer: _this.customer,
              billing: _this.customerBilling,
              property: _this.customerProperty,
              "is-package": _this.lodash.toSafeInteger(_this.isPackage),
            },
          })
        );
      }
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          break;
        case "export_items":
          _this.bulkQuotationExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("quotation");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    },
    updateBulkAction(param) {
      const _this = this;
      switch (param) {
        case "draft":
          _this.bulkQuotationUpdate({
            quotations: _this.selectedRows,
            status: 1,
          });
          break;
        case "accepted":
          _this.bulkQuotationUpdate({
            quotations: _this.selectedRows,
            status: 2,
          });
          break;
        case "rejected":
          _this.bulkQuotationUpdate({
            quotations: _this.selectedRows,
            status: 3,
          });
          break;
        case "pending":
          _this.bulkQuotationUpdate({
            quotations: _this.selectedRows,
            status: 4,
          });
          break;
        case "sent":
          _this.bulkQuotationUpdate({
            quotations: _this.selectedRows,
            status: 5,
          });
          break;
        case "cancelled":
          _this.bulkQuotationUpdate({
            quotations: _this.selectedRows,
            status: 6,
          });
          break;
        case "closed":
          _this.bulkQuotationUpdate({
            quotations: _this.selectedRows,
            status: 7,
          });
          break;
      }
    },
    bulkQuotationUpdate(requestObject) {
      const _this = this;
      _this.btxBulkLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "quotation",
          data: requestObject,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.btxBulkLoading = false;
          _this.selectedRows = [];
          _this.getRows();
        });
    },
    bulkQuotationExport() {
      const _this = this;
      let fileName = new Date();
      _this.exportLoading = true;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: "quotations/export",
        })
        .then(({ data }) => {
          saveAs(data, "quotations-export-" + fileName.getTime() + ".xlsx");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.exportLoading = false;
        });
    },
    get_page_title() {
      if (this.isPackage) {
        return "Package";
      } else {
        return "Summary Report";
      }
    },
    select_package(row) {
      this.selectPackageDialog = false;
      let child_variation_id = 0;
      let parent_variation = ObjectPath.get(row, "id", 0);
      if (row.is_variation) {
        child_variation_id = row.id;
        parent_variation = ObjectPath.get(row, "pre_quotation", 0);
      }
      this.$nextTick(() => {
        this.$router.push(
          this.getDefaultRoute("quotation.create", {
            query: {
              "is-variation": 1,
              variation: parent_variation,
              customer: ObjectPath.get(row, "customer.id", 0),
              is_child_variation: +row.is_variation,
              child_variation: child_variation_id,
              contact_person: ObjectPath.get(row, "contact_person.id", 0),
              new_contact_person: ObjectPath.get(row, "contact_person.id", 0),
              billing: ObjectPath.get(row, "billing.id", 0),
              property: ObjectPath.get(row, "property.id", 0),
            },
          })
        );
      });
    },
    get_quotation_packages() {
      this.search_loader = true;
      this.$store
        .dispatch(QUERY, {
          url: "quotation/packages",
          data: { "only-customer": 1, search: this.search_text },
        })
        .then((response) => {
          this.quotation_packages = ObjectPath.get(response, "data", []);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.search_loader = false;
        });
    },
  },
  mounted() {
    this.resetAll();
    this.get_quotation_packages();

    const page_title = this.get_page_title();

    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: page_title,
      },
    ]);

    SearchEventBus.$emit("search-module-title", page_title);
  },
  beforeMount() {
    const _this = this;

    if (_this.isPackage) {
      _this.routeName = "package.sales";
      _this.pageModule = "package-sales-listing";
      _this.routeDetailName = "package.sales.detail";
    }

    _this.lodash.assign(_this.queryParams, _this.$route.query);

    const page_title = this.get_page_title();

    _this.defaultColDefs = [
      {
        headerName: "",
        field: "id",
        sort: null,
        visible: true,
        fixed: true,
        sortable: false,
        checkbox: true,
        order: 0,
        width: "50px",
      },
      {
        headerName: `${page_title} #`,
        field: "barcode",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 1,
        width: "200px",
      },
      /*{
        headerName: `${page_title} Title / Site Address`,
        field: "quotation_title",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 1,
        width: "300px",
      },*/
      {
        headerName: "Basic Details",
        field: "basic_detail",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 1,
        width: "300px",
      },
      {
        headerName: "Dates",
        field: "date",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 1,
        width: "200px",
      },
      {
        headerName: "Amount",
        field: "amount",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 3,
        width: "200px",
      },
      /*{
        headerName: "Customer Info",
        field: "company_info",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 3,
        width: "300px",
      },*/
      {
        headerName: "Status",
        field: "status",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 3,
        width: "200px",
      },
      {
        headerName: "Created Time",
        child: "created_at",
        field: "added_at",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 9,
        width: "170px",
      },
      {
        headerName: "Last Modified Time",
        child: "modified_at",
        field: "updated_at",
        sort: null,
        visible: false,
        fixed: false,
        sortable: true,
        order: 10,
        width: "170px",
      },
    ];

    const defaultColDefs = _this.lodash.filter(
      _this.defaultColDefs,
      function (row) {
        return row.visible;
      }
    );

    _this.defaultColShow = defaultColDefs.map((col) => col.field);

    _this.status = _this.$route.query.status || _this.status;

    _this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 10;
  },
};
</script>
